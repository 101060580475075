import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.OMNIA]: '0x3ad678e8e77E8E4Fb4D1e6139022194E2858eafc',
  [ChainId.ETHEREUM]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.BSC]: '0x753972Ed9084a99a780d030ff082F2c6a3c6aaBc',
  [ChainId.POLYGON]: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
